@import "@/assets/scss/var.scss";
.login-form {
  &.main {
    display: flex;
  }

  .inner {
    @media (min-width: $mobile) {
      width: 360px;
    }
    margin: auto;
    text-align: center;
  }

  .page-content {
    background: #fff;
    margin: 15px auto;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .shop-info {
    margin-top: auto;
    + .inner {
      margin-top: 20px;
    }
  }
  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  &-operate {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
  }
  .btn-grp {
     button {
       width: 100%;
       display: block;
       margin: 15px 0;
     }
  }

  // 登入页输入框
  .el-autocomplete {
    width: 100%;
  }
}
.codeInput {
  .el-input-group__append div {
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
}

.agreement-content {
  a {
      color: -webkit-link;
  }
}
